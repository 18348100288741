
import { Component, Vue } from 'vue-property-decorator';
import { clone } from 'ramda';
import { Teachers, Auth } from '@/store/modules';
import PayoutDialog from '@/components/PayoutDialog.vue';

@Component({
  components: {
    PayoutDialog
  }
})
export default class Payments extends Vue {
  loading = true;
  error = false;
  upcomingClasses: any[] = [];
  pastClasses: any[] = [];
  availableBalance = 0;
  loadingPayout = false;

  get teacherId() {
    return (Auth.user as any).teacherId;
  }

  async mounted() {
    this.loading = true;
    try {
      const status = await Teachers.getPayoutStatus();
      const upcomingClasses = (await this.$axios.get(`/classes?upcomingAndOngoing=true&teacherId=${this.teacherId}`)).data.items;
      const pastClasses = (await this.$axios.get(`/classes?past=true&teacherId=${this.teacherId}`)).data.items;
      this.upcomingClasses = clone(upcomingClasses);
      this.pastClasses = clone(pastClasses);
      this.availableBalance = Number(status.availablePayout) - Number(status.comission);
    } catch (err) {
      this.error = true;
    }
    this.loading = false;
  }

  openPayoutDialog() {
    (this.$refs as any).payoutDialog.open();
  }
}
