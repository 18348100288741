
import { Vue, Component } from 'vue-property-decorator';
import { clone } from 'ramda';

@Component
export default class extends Vue {
  loading = false;
  loadingPayout = false;
  error = '';
  done = false;
  dialog = false;

  payoutInfo = {
    quoteId: '',
    fee: 0,
    rate: 0,
    targetAmount: 0,
    targetCurrency: ''
  }

  async open() {
    this.loading = true;
    this.dialog = true;

    try {
      const payoutInfoResponse = await this.$axios.get('/payout-info');
      this.payoutInfo = clone(payoutInfoResponse.data);
    } catch (err) {
      this.error = "Failed to fetch payout information. Please try again later.";
    }
    this.loading = false;
  }

  async payout() {
    this.loadingPayout = true;
    try {
      await this.$axios.post(`/teachers/payout`, { quoteId: this.payoutInfo.quoteId });
      this.done = true;
    } catch (err) {
      this.error = "Failed to complete payout. Please try again later or contact us if the problem persists.";
      console.warn(err.message);
    }
    this.loadingPayout = false;
  }

  finalize() {
    window.location.reload()
  }
}
